const gatsby = require('gatsby');
const { getLocalization } = require('./pages/utils/get-localization');
const getRedirectionLink = require('./get-redirection-link').getRedirectionLink;

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  jumpToLocation(location.href);
  return false;
};

exports.onPreRouteUpdate = ({ location }) => {
  const redirection = getRedirectionLink(location.pathname);
  if (redirection) {
    const isInternalNavigation = redirection.substring(0, 4) !== 'http';
    if (isInternalNavigation) {
      gatsby.navigate(redirection);
    } else {
      document.location.href = redirection;
    }
  }

  getLocalization(location.pathname);
};

const jumpToLocation = url => {
  const element = getElementFromUrl(url);
  scrollToElement(element);
};

const getElementFromUrl = url => {
  const elementID = url.split('#')[1];
  return document.getElementById(elementID);
};

const scrollToElement = element => {
  if (!element) {
    window.scroll(0, 0);
    return false;
  }

  element.scrollIntoView();
  let topPos = 0;
  const header = document.querySelector('header');
  if (header) {
    topPos += header.getBoundingClientRect().height;
  }

  const secondaryMenu = document.querySelector('.secondary-menu .secondary-menu-label-wrap');
  if (secondaryMenu) {
    topPos += secondaryMenu.getBoundingClientRect().height;
  }

  window.scrollBy(0, -topPos);
};

exports.onInitialClientRender = () => setTimeout(() => jumpToLocation(window.location.href), 200);
