const getLocalization = url => {
  /*
    Fetch the Country and Currency
    Cache it in localStorage for 5 minutes
  */
  const FIVE_MINUTES = 1000 * 60 * 5;
  const storeKey = 'localization';
  const apiUrl = process.env.GATSBY_CLOUD_API_URL + 'store/api/localization/';
  let refreshLocalization = true;

  try {
    const store = localStorage.getItem(storeKey);
    const date = JSON.parse(store).timestamp;
    const fiveMinutesAgo = Date.now() - FIVE_MINUTES;

    refreshLocalization = date < fiveMinutesAgo;
  } catch (e) {
    refreshLocalization = true;
  }

  if (refreshLocalization) {
    fetch(apiUrl)
      .then(response => response.json())
      .then(resultData => {
        localStorage.setItem(
          storeKey,
          JSON.stringify({
            ...resultData,
            timestamp: new Date().getTime(),
          }),
        );
        if (window) {
          var event = new Event('localizationUpdated');
          window.dispatchEvent(event);
        }
      })
      .catch(() => {});
  }
};

module.exports = { getLocalization };
