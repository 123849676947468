/* Internal SPA redirections, should be sync with lambda redirection map! */
const internalLinkToExternalRedirections = {
    '/product/vidoc-rtk-rover/': '/product/rtk/',
    '/fr/produit/vidoc-rtk-rover/': '/fr/produit/rtk/',
    '/de/produkt/vidoc-rtk-rover/': '/de/produkt/rtk/',
    '/it/prodotto/vidoc-rtk-rover/': '/it/prodotto/rtk/',
    '/pt/produto/vidoc-rtk-rover/': '/pt/produto/rtk/',
    '/es/producto/vidoc-rtk-rover/': '/es/producto/rtk/',
    '/pricing/vidoc-rtk-rover/': '/pricing/rtk/',
    '/fr/prix/vidoc-rtk-rover/': '/fr/prix/rtk/',
    '/de/preise/vidoc-rtk-rover/': '/de/preise/rtk/',
    '/it/prezzi/vidoc-rtk-rover/': '/it/prezzi/rtk/',
    '/pt/precos/vidoc-rtk-rover/': '/pt/precos/rtk/',
    '/es/precios/vidoc-rtk-rover/': '/es/precios/rtk/',
    '/join-the-waiting-list-now': '/purchase/',
    '/industry/agriculture/fusion': '/industry/agriculture',
    '/pix4dcatch-vidoc-rtk-android': 'https://support.pix4d.com/hc/en-us/articles/4524763187229',
    '/user-conference': '/user-conference-madrid',
    '/blog/UCPix4D-2019': '/user-conference',
    '/blog/webinar-drone-mapping-large-areas': '/webinar-challenges-surveying-large-areas',
    '/terms-and-policies/cloud-terms-of-service': '/terms-and-policies/website-terms-of-use',
    '/blog/webinar-crane-camera-solution-construction-eu':
        '/webinar-crane-camera-an-automous-solution-for-construction',
    '/blog/webinar-crane-camera-solution-construction-us':
        '/pix4d-crane-camera-solution-fully-autonomous-alternative-to-drones-webinar-us',
    '/blog/drone-mapping-for-construction-webinar': '/webinar-drone-mapping-for-construction',
    '/do-japan-utc-program': '/education',
    '/jp/do-japan-utc-program': '/jp/education',
    '/do-japan-utc-program-recording': '/jp/education',
    '/jp/do-japan-utc-program-recording': '/jp/education',
    '/blog/climate': '/climate',
    '/blog/digital-agriculture-webinar': '/digital-agriculture-webinar',
    '/blog/climate-change-contest': '/climate-contest',
    '/blog/rapid-tactical-mapping-webinar': '/rapid-tactical-mapping-for-public-safety-webinar',
    '/blog/drones-agriculture-ebook':
        '/drones-in-agriculture-seeing-beyond-the-surface-with-smart-farming',
    '/blog/video-tutorials':
        'https://support.pix4d.com/hc/en-us/articles/360032118251',
    '/de/blog/weitere-artikel': '/blog',
    '/jp/blog/more-articles': '/blog',
    '/es/blog/mas-articulos': '/blog',
    '/public-safety': '/industry/public-safety',
    '/become-a-pix4d-association': '/partners',
    '/pix4d-associations': '/partners',
    '/blog/more-articles': '/subscribe-to-our-newsletters',
    '/blog/4-reasons-join-pix4d-community': '/blog/join-pix4d-community',
    '/pix4dmapper/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Gm0UGAZ9WQMSOewk6Kya4/816a0735dd93eafb3d912520939bebfe/Pix4Dmapper_feature_list_v4.6_English.pdf',
    '/jp/pix4dmapper/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Gm0UGAZ9WQMSOewk6Kya4/92ecdd27b1ed0cda8fc075aebd5ea9cf/Pix4Dmapper_feature_list_v4.6_Japanese.pdf',
    '/es/pix4dmapper/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Gm0UGAZ9WQMSOewk6Kya4/88666a43dd610a6e55dd50b8fd195a7a/PIX4Dmapper_lista_de_caracteristicas_4.6.pdf',
    '/pt/pix4dmapper/lista-de-funcoes':
        'https://assets.ctfassets.net/go54bjdzbrgi/6yrEAtElZR7MdmnVqGbKj1/4238e2f2101ba8a42d80202414981864/PIX4Dmapper_lista_de_funcoes_4.6.pdf',
    '/pix4dfields/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/3naYjEZqohHhLRUyh4vYt9/9a04883a2282a74340cbb330aff124c6/PIX4Dfields_v1.12_Feature_list.pdf',
    '/jp/pix4dfields/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/3naYjEZqohHhLRUyh4vYt9/eb96dfc22be706b498acc76c6bf5f3c0/PIX4Dfields_v1.12_feature_list_Japanese.pdf',
    '/de/pix4dfields/produktfunktionen':
        'https://assets.ctfassets.net/go54bjdzbrgi/3naYjEZqohHhLRUyh4vYt9/5563edbebff1e8abcfcb46911bac21db/PIX4Dfields_v1.12_Liste_der_Productfunctionen.pdf',
    '/es/pix4dfields/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/3naYjEZqohHhLRUyh4vYt9/16ef96c75f1593c8e2470548420f0fe2/PIX4Dfields_v1.12_Lista_de_caracteristicas.pdf',
    '/fr/pix4dfields/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/3naYjEZqohHhLRUyh4vYt9/6cd99bde07b45094156072286864a2ef/PIX4Dfields_v1.12_Liste_des_caracteristiques.pdf',
    '/ru/pix4dfields/spisok-funktsiy':
        'https://assets.ctfassets.net/go54bjdzbrgi/2SxN0xLXZUJ6DLAJnghZwO/baecd43bbb01c0a81b5851b11644ddb9/PIX4Dfields_v.1.11_Spisok_funktsiy_Russian.pdf',
    '/it/pix4dfields/elenco-delle-caratteristiche':
        'https://assets.ctfassets.net/go54bjdzbrgi/7Gqx65A7fMSoLGKI7WsF8q/c8996cdca79f5214636baa3245527fa4/PIX4Dfields_v1.12_Elenco_delle_caratteristiche.pdf',
    '/ua/pix4dfields/spisok-funktsiy':
        'https://assets.ctfassets.net/go54bjdzbrgi/3XEQ40x3AbZrQtXmmSO074/b2560d9df525d3a84564eec90fa2fac0/PIX4Dfields_v.1.11_Spisok_funktsiy_Ukrainian.pdf',
    '/cn/pix4dfields/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/3QQ9jC1bB0TILGLNX4hBpv/8ddcffa450f7631859f1ab13be4a7541/PIX4Dfields_v1.12_Feature_list_Chinese.pdf',
    '/pix4dreact/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/ef74d1ea67540f8e76fec18eec7373dc/PIX4Dreact_v1.4_feature_list.pdf',
    '/pix4dreact/checklist':
        'https://assets.ctfassets.net/go54bjdzbrgi/54hH2nU8SJ9feSEx3wEW2i/2ecba7bfce7abb62da6fa127a1eb1ec7/PIX4Dreact_checklist.pdf',
    '/jp/pix4dreact/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/71585eba148c6ffc27e79eb9203d3650/PIX4Dreact_v1.4_feature_list_Japanese.pdf',
    '/de/pix4dreact/productfunktionen':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/c5947505c08f41591053df3fc64c5938/PIX4Dreact_v1.4_Liste_der_Productfunctionen.pdf',
    '/fr/pix4dreact/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/65f4f78b64cab3b3fe2a4638ef2f379a/PIX4Dreact_v1.4_Liste_des_caracteristiques.pdf',
    '/es/pix4dreact/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/3653e54f91542de83f4484f737036702/PIX4Dreact_v1.4_Lista_de_caracteristicas.pdf',
    '/ru/pix4dreact/spisok-funktsiy':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/8003e46c8f209aa08c4a480c4928d075/PIX4Dreact_v1.4_Spisok_funktsiy.pdf',
    '/it/pix4dreact/elenco-delle-caratteristiche':
        'https://assets.ctfassets.net/go54bjdzbrgi/76TeLPvMSHClWViHwDAGL3/99661bba939b7db68ca5056b75228046/PIX4Dreact_v1.4_Elenco_delle_caratteristiche.pdf',
    '/ua/pix4dreact/spisok-funktsiy':
        'https://assets.ctfassets.net/go54bjdzbrgi/1AlWjrg7YsSzkkZcsgSu6k/8d4bef4798dfab180e23b1d4a0fb7a1d/PIX4Dreact_v1.4_Spisok_funktsiy_UA.pdf',
    '/cn/pix4dreact/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1AlWjrg7YsSzkkZcsgSu6k/d2cb6a08c88061220c9a808804bc2de6/PIX4Dreact_v1.4_feature_list_-_Chinese.pdf',
    '/pix4dsurvey/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/5LiAJkHQG38w84nfeS66DB/061ad7ff3b379514166bd837b07d8402/PIX4Dsurvey_v1.30_feature_list.pdf',
    '/jp/pix4dsurvey/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/5LiAJkHQG38w84nfeS66DB/7a002eaae18a2dba01eb6e0d78d4bcc8/PIX4Dsurvey_v1.30_Feature_list_Japanese.pdf',
    '/de/pix4dsurvey/produktfunktionen':
        'https://assets.ctfassets.net/go54bjdzbrgi/5LiAJkHQG38w84nfeS66DB/bae1b0d5c3a02d30e00184abc14f74da/PIX4Dsurvey_v1.30_Liste_der_Productfunctionen.pdf',
    '/es/pix4dsurvey/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/5LiAJkHQG38w84nfeS66DB/ab79780d5629467008d920f3edd59c35/PIX4Dsurvey_v1.30_Lista_de_caracteristicas.pdf',
    '/fr/pix4dsurvey/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/5LiAJkHQG38w84nfeS66DB/3491147df3b91d349bb586bb8f312436/PIX4Dsurvey_v1.30_Liste_des_caracteristiques.pdf',
    '/pt/pix4dsurvey/liste-des-funcoes':
        'https://assets.ctfassets.net/go54bjdzbrgi/5LiAJkHQG38w84nfeS66DB/0e12d5cf24211d775bb84d8c64a92de8/PIX4Dsurvey_v1.30_Liste_des_funcoes.pdf',
    '/cn/pix4dsurvey/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1717d23wuFusmhsnOQcpOM/63b44ce3b8c6688a702fc5db7a353288/PIX4Dsurvey_v1.30_feature_list_Chinese.pdf',
    '/pix4dinspect/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/2XF4vQZYegF7ajdqTBHjXV/25252dda00223db864401a845083b15d/PIX4Dinspect_feature_list.pdf',
    '/jp/pix4dinspect/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/2XF4vQZYegF7ajdqTBHjXV/ede7bed8aa37c7e0884190ba252846be/PIX4Dinspect_feature_list_-_Japanese.pdf',
    '/fr/pix4dinspect/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/2XF4vQZYegF7ajdqTBHjXV/8266fae620c384cc2cc89fb5f1de3e49/PIX4Dinspect_Liste_des_caracteristiques.pdf',
    '/pt/pix4dinspect/liste-des-funcoes':
        'https://assets.ctfassets.net/go54bjdzbrgi/2XF4vQZYegF7ajdqTBHjXV/caf56b9776622ff04f613330da90f0b7/PIX4Dinspect_Liste_des_funcoes.pdf',
    '/es/pix4dinspect/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/2XF4vQZYegF7ajdqTBHjXV/6bb916f034d00cf3ee4a6c0406c94361/PIX4Dinspect_Lista_de_caracteristicas.pdf',
    '/it/pix4dinspect/elenco-delle-caratteristiche':
        'https://assets.ctfassets.net/go54bjdzbrgi/1IZQokSRkZQAlG1MEoqQnk/f6fd610f8697facaab2e750387908b5c/PIX4Dinspect_Elenco_delle_caratteristiche.pdf',
    '/pix4dcloud/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/2jiKqezNs5qhcB8OVzJzpm/4158430e2f8ebb873e55e3f135e2b1c4/PIX4Dcloud_feature_list.pdf',
    '/jp/pix4dcloud/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/4YWqrhluW4tKJi81OfZtoC/72951862c0d567b7e371c9fe58119aed/Pix4Dcloud_and_Pix4Dcloud_Advanced_comparison_table_-_Japanese.pdf',
    '/es/pix4dcloud/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/7LgdEoZBn6OymoNgujPDR/6db128506d343df910fa9b4db594c235/PIX4Dcloud_lista_de_caracteristicas.pdf',
    '/pix4dcloud-advanced/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/6hcma2XXD5fdjrqb0DcDyr/bc23cdebf4ccb19542625a80bbb86ce6/PIX4Dcloud_advanced_feature_list.pdf',
    '/es/pix4dcloud-advanced/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/5RJDt5OrAbxKpg7NaipYMV/31a33718be3381c4ee228d534a54684e/PIX4Dcloud_Advanced_lista_de_caracteristicas.pdf',
    '/pix4dcloud-comparison-table':
        'https://assets.ctfassets.net/go54bjdzbrgi/4YWqrhluW4tKJi81OfZtoC/9ee61fe99abbeef3f0106f41e929b814/Pix4Dcloud_and_Pix4Dcloud_Advanced_comparison_table_-_English.pdf',
    '/pix4dmatic/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/721sQHCjapsmmMMJvTaJX3/e6d8860086e610b73b04043a6e01c110/PIX4Dmatic_v1.31_Feature_list.pdf',
    '/jp/pix4dmatic/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/721sQHCjapsmmMMJvTaJX3/e2954c6706aa4d08580751d8e9213a64/PIX4Dmatic_v1.31_feature_list_Japanese.pdf',
    '/de/pix4dmatic/produktfunktionen':
        'https://assets.ctfassets.net/go54bjdzbrgi/721sQHCjapsmmMMJvTaJX3/e1a2c8c6bfdab7d1f4d960961d0b4500/PIX4Dmatic_v1.31_Liste_der_Productfunctionen.pdf',
    '/es/pix4dmatic/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/721sQHCjapsmmMMJvTaJX3/3cf581da270d92d4581377ba54625fe0/PIX4Dmatic_v1.31_Lista_de_caracteristicas.pdf',
    '/fr/pix4dmatic/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/721sQHCjapsmmMMJvTaJX3/a4d42504ebde682dd2038d0230742d28/PIX4Dmatic_v1.31_Liste_des_caracteristiques.pdf',
    '/pt/pix4dmatic/liste-des-funcoes':
        'hhttps://assets.ctfassets.net/go54bjdzbrgi/721sQHCjapsmmMMJvTaJX3/19526e67f3d509d201b2d494da2e151c/PIX4Dmatic_v1.31_Liste_des_funcoes.pdf',
    '/cn/pix4dmatic/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/4wdRC8uoV18ZBDbA9VN9k4/2c7433b0161226f18523d18f44d38880/PIX4Dmatic_v1.31_feature_list_Chinese.pdf',
    '/compare-solutions':
        'https://assets.ctfassets.net/go54bjdzbrgi/3JIwRqYwLur83zOlBmQJ7u/23a0e44cd74ba2b871f5d2c1f901438d/Pix4D_products_master_comparison_table.pdf',
    '/pix4dengine/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1YC0NhHXdNHauU7rFiRJLT/c51cb3fc236b740fb48beab26dd23f84/PIX4Dengine_SDK_v2_feature_list.pdf',
    '/jp/pix4dengine/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1YC0NhHXdNHauU7rFiRJLT/ad067e1add7e3ac6035e6cf99fb35140/PIX4Dengine_SDK_v2_feature_list_-_Japanese.pdf',
    '/de/pix4dengine/produktfunktionen':
        'https://assets.ctfassets.net/go54bjdzbrgi/1YC0NhHXdNHauU7rFiRJLT/124f17f57d24b67b635218895a2d32bb/PIX4Dengine_SDK_v2_Liste_der_Productfunctionen.pdf',
    '/es/pix4dengine/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/1YC0NhHXdNHauU7rFiRJLT/bbe2e9ad0583d9e6ea6191db8a3d36af/PIX4Dengine_SDK_v2_Lista_de_caracteristicas.pdf',
    '/ru/pix4dengine/spisok-funktsiy':
        'https://assets.ctfassets.net/go54bjdzbrgi/1YC0NhHXdNHauU7rFiRJLT/5b1ddc43c04c63794b504b3dede09c56/PIX4Dengine_SDK_v2_Spisok_funktsiy.pdf',
    '/fr/pix4dengine/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/1YC0NhHXdNHauU7rFiRJLT/a9c7543e5eb62ddd6b937ba6c5cc91b5/PIX4Dengine_SDK_v2_Liste_des_caracteristiques.pdf',
    '/pix4dengine-v1/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Jm0HbAS0wLaFKXhXtxpC6/ff7be74399e5b24f8394b0ba5dd7b661/PIX4Dengine_SDK_v1_feature_list.pdf',
    '/jp/pix4dengine-v1/feature-list':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Jm0HbAS0wLaFKXhXtxpC6/e603dcad4e4c659cce836b8328a9423f/PIX4Dengine_SDK_v1_feature_list_-_Japanese.pdf',
    '/de/pix4dengine-v1/produktfunktionen':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Jm0HbAS0wLaFKXhXtxpC6/4869d99a5231417b02f01a9e094d880a/PIX4Dengine_SDK_v1_Liste_der_Productfunctionen.pdf',
    '/es/pix4dengine-v1/lista-de-caracteristicas':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Jm0HbAS0wLaFKXhXtxpC6/f57ed023b0d00f0f4109eb0241c50fdf/PIX4Dengine_SDK_v1_Lista_de_caracteristicas.pdf',
    '/fr/pix4dengine-v1/liste-des-caracteristiques':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Jm0HbAS0wLaFKXhXtxpC6/dd8f07f5bdd9989440c9f23ebe9ca5b9/PIX4Dengine_SDK_v1_Liste_des_caracteristiques.pdf',
    '/ru/pix4dengine-v1/spisok-funktsiy':
        'https://assets.ctfassets.net/go54bjdzbrgi/1Jm0HbAS0wLaFKXhXtxpC6/3c12e20ffd26ce3ed83fa4c30f334fd8/PIX4Dengine_SDK_v1_Spisok_funktsiy.pdf',
    '/services/training-certification': 'https://training.pix4d.com/',
    '/services/training-certification/workshops': 'https://training.pix4d.com/pages/workshops',
    '/services/training-certification#certification':
        'https://training.pix4d.com/pages/pix4d-certification',
    '/services/training-certification/workshops#list': 'https://training.pix4d.com/pages/workshops',
    '/services/training-certification/request-a-quote':
        'https://training.pix4d.com/pages/request-a-quote',
    '/services/training-certification/pix4d-user-certification':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-certification-exam',
    '/es/servicios/capacitacion-certificacion/certificacion-pix4d':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-certification-exam-es',
    '/jp/services/training-certification/pix4d-user-certification':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-certification-exam-jp',
    '/de/dienstleistungen/schulung-zertifizierung/pix4d-zertifikat':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-certification-exam',
    '/services/training-certification/online-course':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-online-course',
    '/es/servicios/capacitacion-certificacion/cursos-en-linea':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-online-course-es',
    '/jp/services/training-certification/online-course':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-online-course-jp',
    '/de/dienstleistungen/schulung-zertifizierung/online-kurse':
        'https://training.pix4d.com/courses/pix4dmapper-essentials-online-course',
    '/product/rtk-rover': '/product/vidoc-rtk-rover',
    '/product/vidoc-rtk-rover/whitepaper':
        'https://s3.amazonaws.com/mics.pix4d.com/viDoc_Pix4D/Pix4D_viDoc_RTK_rover_Accuracy_WHITE_PAPER.pdf',
    '/product/vidoc-rtk-rover/technical-data-spec':
        'https://s3.amazonaws.com/mics.pix4d.com/viDoc_Pix4D/viDoc_Technical_Data_vigram_Pix4D.pdf',
    '/mapper': '/product/pix4dmapper-photogrammetry-software',
    '/jp/mapper': '/jp/product/pix4dmapper-photogrammetry-software',
    '/de/mapper': '/de/produkt/pix4dmapper-photogrammetrie-software',
    '/es/mapper': '/es/producto/pix4dmapper-fotogrametria-software',
    '/catch': '/product/pix4dcatch',
    '/jp/catch': '/jp/product/pix4dcatch',
    '/cloud': '/product/pix4dcloud',
    '/jp/cloud': '/jp/product/pix4dcloud',
    '/de/cloud': '/de/produkt/pix4dcloud',
    '/es/cloud': '/es/producto/pix4dcloud',
    '/react': '/product/pix4dreact',
    '/jp/react': '/jp/product/pix4dreact',
    '/es/react': '/es/producto/pix4dreact',
    '/de/react': '/de/produkt/pix4dreact',
    '/fr/react': '/fr/produit/pix4dreact',
    '/ru/react': '/ru/produkt/pix4dreact',
    '/fields': '/product/pix4dfields',
    '/jp/fields': '/jp/product/pix4dfields',
    '/es/fields': '/es/producto/pix4dfields',
    '/de/fields': '/de/produkt/pix4dfields',
    '/fr/fields': '/fr/produit/pix4dfields',
    '/ru/fields': '/ru/produkt/pix4dfields',
    '/matic': '/product/pix4dmatic-large-scale-photogrammetry-software',
    '/jp/matic': '/jp/product/pix4dmatic-large-scale-photogrammetry-software',
    '/es/matic': '/es/producto/pix4dmatic-software-fotogrametria-a-gran-escala',
    '/de/matic': '/de/produkt/pix4dmatic-grossen-stil-photogrammetrie-software',
    '/product/pix4dmatic': '/product/pix4dmatic-large-scale-photogrammetry-software',
    '/inspect': '/product/pix4dinspect-inspection-asset-management-software',
    '/jp/inspect': '/jp/product/pix4dinspect-inspection-asset-management-software',
    '/inspection': '/industry/inspection',
    '/product/pix4dinspect': '/product/pix4dinspect-inspection-asset-management-software',
    '/product/pix4dscan': '/product/pix4dcapture',
    '/product/pix4dcapture-pro': '/product/pix4dcapture',
    '/product/pix4dcapture_pro': '/product/pix4dcapture',
    '/product/pix4dcapturepro': '/product/pix4dcapture',
    '/product/pix4dscan-drone-flight-inspection-software': '/product/pix4dcapture',
    '/produkt/pix4dscan': '/product/pix4dcapture',
    '/producto/pix4dscan': '/product/pix4dcapture',
    '/produit/pix4dscan-drone-flight-inspection-software': '/product/pix4dcapture',
    '/produto/pix4dscan-drone-software-de-inspecao-de-voo': '/product/pix4dcapture',
    '/prodotto/pix4dscan-software-di-ispezione-del-volo-dei-droni': '/product/pix4dcapture',
    '/scan': '/product/pix4dcapture',
    '/jp/scan': '/jp/product/pix4dcapture',
    '/survey': '/product/pix4dsurvey',
    '/jp/survey': '/jp/product/pix4dsurvey',
    '/es/survey': '/es/producto/pix4dsurvey',
    '/crane-camera': '/product/pix4dcloud/',
    '/jp/crane-camera': '/jp/product/pix4dcloud/',
    '/es/crane-camera': '/es/producto/pix4dcloud',
    '/de/crane-camera': '/de/produkt/pix4dcloud',
    '/cranecamera': '/product/pix4dcloud',
    '/jp/cranecamera': '/jp/product/pix4dcloud',
    '/es/cranecamera': '/es/producto/pix4dcloud',
    '/de/cranecamera': '/de/produkt/pix4dcloud',
    '/product/crane-camera' : '/product/pix4dcloud/',
    '/jp/product/crane-camera' : '/jp/product/pix4dcloud/',
    '/es/producto/camara-de-grua' : '/es/producto/pix4dcloud/',
    '/de/produkt/crane-camera' : '/de/produkt/pix4dcloud/',
    '/it/prodotto/crane-camera/' : '/it/prodotto/pix4dcloud/',
    '/fr/produit/crane-camera/' : '/fr/produit/pix4dcloud/',
    '/pt/produto/crane-camera/' : '/pt/produto/pix4dcloud/',
    '/rtk': '/product/vidoc-rtk-rover',
    '/vidoc': '/product/vidoc-rtk-rover',
    '/jp/vidoc': '/jp/product/vidoc-rtk-rover',
    '/es/vidoc': '/es/producto/vidoc-rtk-rover',
    '/de/vidoc': '/de/produkt/vidoc-rtk-rover',
    '/fr/vidoc': '/fr/produit/vidoc-rtk-rover',
    '/ru/vidoc': '/ru/produkt/vidoc-rtk-rover',
    '/blog/presenting-pix4d-new-professional-products-eu':
    '/webinar-pix4d-new-professional-products-eu',
    '/jp/blog/presenting-pix4d-new-professional-products-japan':
    '/jp/webinar-pix4d-new-professional-products-japan',
    '/webinar-pix4d-new-professional-products-us': '/webinar-pix4d-new-professional-products-eu',
    '/webinar-pix4dinspect-us': '/webinar-pix4dinspect-eu',
    '/digital-agriculture-webinar': '/webinar-introducing-pix4dfields-1-9',
    '/recording-quality-meets-speed-in-digital-agriculture': '/webinar-introducing-pix4dfields-1-9',
    '/blog/webinar-drone-mapping-for-planting-and-harvesting': '/webinar-introducing-pix4dfields-1-9',
    '/mapping-large-areas-with-drones-webinar': '/webinar-challenges-surveying-large-areas',
    '/webinar-drone-mapping-for-planting-and-harvesting': '/webinar-introducing-pix4dfields-1-9',
    '/recording-bridge-the-gap-between-planting-and-harvesting':
        '/webinar-recording-introducing-pix4dfields-1-9',
    '/webinar-drone-mapping-for-agriculture': '/webinar-agriculture-drone-mapping-and-analytics',
    '/blog/ebook-pushing-construction-monitoring-limits':
        '/pushing-the-limits-of-construction-monitoring-with-the-crane-camera',
    '/de/blog/webinar-planung-teilflaechenspezifischer-anwendungen':
        '/de/webinar-planung-teilflaechenspezifischer-anwendungen-und-digitale-bonitur',
    '/blog/webinar-bridge-the-gap-between-photogrammetry-and-CAD':
        '/webinar-pix4dsurvey-bridge-the-gap-between-photogrammetry-and-CAD-us',
    '/blog/drones-public-safety-ebook': '/drones-in-public-safety-the-bigger-picture',
    '/product/pix4dbim': '/blog/new-pix4dcloud-release',
    '/es/producto/pix4dbim': '/blog/new-pix4dcloud-release',
    '/de/produkt/pix4dbim': '/blog/new-pix4dcloud-release',
    '/jp/product/pix4dbim': '/jp/blog/new-pix4dcloud-release',
    '/product/pix4dmodel': '/blog/new-pix4dcloud-release',
    '/pix4dmapper-demo': '/discover-pix4dmapper',
    '/fr/etude-de-cas-comment-planifier-un-atelier-de-formation-en-photogrammetrie':
        '/fr/blog/etude-de-cas-comment-planifier-un-atelier-de-formation-en-photogrammetrie',
    '/fr/logiciel-decision-urgence': '/fr/blog/logiciel-decision-urgence',
    '/ru/kak-izbezhat-oshibok-pri-stroitelstve-i-dorogostoyashikh-dorabotok':
        '/ru/blog/kak-izbezhat-oshibok-pri-stroitelstve-i-dorogostoyashikh-dorabotok',
    '/ru/kartografirovanie-s-pomoshyu-dronov-dlya-lyubogo-tipa-stroitelnykh-proektov':
        '/ru/blog/kartografirovanie-s-pomoshyu-dronov-dlya-lyubogo-tipa-stroitelnykh-proektov',
    '/ru/preimushestva-vizualnoi-dokumentacii-v-stroitelstve':
        '/ru/blog/preimushestva-vizualnoi-dokumentacii-v-stroitelstve',
    '/ru/osmotr-odnoi-iz-krupneishikh-promyshlennykh-ploshadok-s-pomoshyu-dronov':
        '/ru/blog/osmotr-odnoi-iz-krupneishikh-promyshlennykh-ploshadok-s-pomoshyu-dronov',
    '/ru/effektivnoe-upravlenie-stroitelstvom-s-pix4dcloud-advanced':
        '/ru/blog/effektivnoe-upravlenie-stroitelstvom-s-pix4dcloud-advanced',
    '/ru/osmotr-kryshi-v-10-raz-bystree-s-pomoshyu-dronov':
        '/ru/blog/osmotr-kryshi-v-10-raz-bystree-s-pomoshyu-dronov',
    '/ru/kogda-dannye-s-bespilotnika-i-pix4dcloud-spasayut-polozhenie':
        '/ru/blog/kogda-dannye-s-bespilotnika-i-pix4dcloud-spasayut-polozhenie',
    '/ru/tsennyye-kultury-i-vozdelyvaniye-vinograda':
        '/ru/blog/tsennyye-kultury-i-vozdelyvaniye-vinograda',
    '/ru/ot-agro-vyzovov-do-effektivnykh-rabochikh-protsessov':
        '/ru/blog/ot-agro-vyzovov-do-effektivnykh-rabochikh-protsessov',
    '/ru/drony-i-differenyirovannoye-vneseniye-udobreniy':
        '/ru/blog/drony-i-differenyirovannoye-vneseniye-udobreniy',
    '/ru/product/pix4dfields/rezultaty': '/ru/produkt/pix4dfields/rezultaty',
    '/fr/tarifs/pix4dfields': '/fr/prix/pix4dfields',
    '/ru/stoimost/pix4dfields': '/ru/cenoobrazovanie/pix4dfields',
    '/special-offer': '/cyber-monday',
    '/jp/special-offer': '/jp/cyber-monday',
    '/es/special-offer': '/es/cyber-monday',
    '/de/special-offer': '/de/cyber-monday',
    '/fr/special-offer': '/fr/cyber-monday',
    '/ru/special-offer': '/ru/cyber-monday',
    '/pt/special-offer': '/pt/cyber-monday',
    '/request-pix4dreact': '/product/pix4dreact',
    '/product/pix4dinspect-inspection-asset-management-software': '/product/pix4dcloud/',
    '/fr/produit/pix4dinspect-inspection-gestion-des-actifs-logiciel': '/fr/produit/pix4dcloud/',
    '/de/produkt/pix4dinspect': '/de/produkt/pix4dcloud/',
    '/it/prodotto/pix4dinspect-software-pe-la-gestione-degli-asset-di-ispezione': '/it/prodotto/pix4dcloud/',
    '/jp/product/pix4dinspect-inspection-asset-management-software': '/jp/product/pix4dcloud/',
    '/pt/produto/pix4dinspect-inspecao-ativo-gerenciamento-software': '/pt/produto/pix4dcloud/',
    '/es/producto/pix4dinspect': '/es/producto/pix4dcloud/',
    '/pricing/pix4dinspect': '/pricing/pix4dcloud/',
'/fr/prix/pix4dinspect': '/fr/prix/pix4dcloud/',
'/de/preise/pix4dinspect': '/de/preise/pix4dcloud/',
'/it/prezzi/pix4dinspect': '/it/prezzi/pix4dcloud/',
'/jp/pricing/pix4dinspect': '/jp/pricing/pix4dcloud/',
'/pt/precos/pix4dinspect': ' /pt/precos/pix4dcloud/',
'/es/precios/pix4dinspect': '/es/precios/pix4dcloud/',
'/kr/product/pix4dinspect': '/kr/product/pix4dcloud/',
'/download/pix4dmatic': '/download-software/',
'/fr/telecharger/pix4dmatic': '/fr/telechargement-de-logiciels/',
'/de/download/pix4dmatic': '/de/download-software/',
'/it/download/pix4dmatic': '/it/scaricare-il-software/',
'/jp/download/pix4dmatic': '/jp/download-software/',
'/pt/descarregar/pix4dmatic': '/pt/descarregar-software/',
'/es/descargar/pix4dmatic': '/es/centro-de-descarga/',
'/download/pix4dsurvey': '/download-software/',
'/fr/telecharger/pix4dsurvey': '/fr/telechargement-de-logiciels/',
'/de/download/pix4dsurvey': '/de/download-software/',
'/it/download/pix4dsurvey': '/it/scaricare-il-software/',
'/jp/download/pix4dsurvey': '/jp/download-software/',
'/pt/descarregar/pix4dsurvey': '/pt/descarregar-software/',
'/es/descargar/pix4dsurvey': '/es/centro-de-descarga/',
'/download/pix4dmapper': '/download-software/',
'/fr/telecharger/pix4dmapper': '/fr/telechargement-de-logiciels/',
'/de/download/pix4dmapper': '/de/download-software/',
'/it/download/pix4dmapper': '/it/scaricare-il-software/',
'/jp/download/pix4dmapper': '/jp/download-software/',
'/pt/descarregar/pix4dmapper': '/pt/descarregar-software/',
'/es/descargar/pix4dmapper': '/es/centro-de-descarga/',
'/download/pix4dreact': '/download-software/',
'/fr/telecharger/pix4dreact': '/fr/telechargement-de-logiciels/',
'/de/download/pix4dreact': '/de/download-software/',
'/it/download/pix4dreact': '/it/scaricare-il-software/',
'/jp/download/pix4dreact': '/jp/download-software/',
'/pt/descarregar/pix4dreact': '/pt/descarregar-software/',
'/es/descargar/pix4dreact': '/es/centro-de-descarga/',
'/download/pix4dfields': '/download-software/',
'/fr/telecharger/pix4dfields': '/fr/telechargement-de-logiciels/',
'/de/download/pix4dfields': '/de/download-software/',
'/it/download/pix4dfields': '/it/scaricare-il-software/',
'/jp/download/pix4dfields': '/jp/download-software/',
'/pt/descarregar/pix4dfields': '/pt/descarregar-software/',
'/es/descargar/pix4dfields': '/es/centro-de-descarga/',
'/discover-vidoc-rtk-rover-for-public-safety/': '/forensics/',
'/industry/public-safety/': '/industry/forensics-public-safety/',
'/fr/industrie/securite-publique/': '/fr/industrie/criminalistique-securite-publique/',
'/de/industrie/offentliche-sicherheit/': '/de/industrie/forensik-offentliche-sicherheit/',
'/it/industria/sicurezza-pubblica/': '/it/industria/forense-sicurezza-pubblica/',
'/jp/industry/public-safety/': '/jp/industry/forensics-public-safety/',
'/pt/industria/seguranca-publica/': '/pt/industria/forense-seguranca-publica/',
'/es/industria/seguridad-publica//': '/es/industria/forense-seguridad-publica/',
};

const getRedirectionLink = pathname => {
    return internalLinkToExternalRedirections[pathname];
};

exports.getRedirectionLink = getRedirectionLink;
